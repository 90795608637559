<template>
  <v-navigation-drawer app disable-resize-watcher dark color="black" :value="showDrawer" @input="onDrawerUpdate">
    <v-list>
      <v-list-item class="px-2">
        <v-list-item-avatar>
          <v-img
            v-if="currentProfile && currentProfile.Avatar"
            :src="currentProfile.Avatar"
            height="40"
            width="40"
            color="transparent"
            class="circural flex-grow-0"
          ></v-img>
        </v-list-item-avatar>
      </v-list-item>

      <v-list-item v-if="currentProfile && currentProfile.DisplayName" :to="!loading && profileLink ? profileLink : false">
        <v-list-item-content>
          <v-list-item-title class="title">{{ currentProfile.DisplayName }}</v-list-item-title>
          <v-list-item-subtitle v-if="hasSubscribeModule && $can('edit','Subscription') && $can('edit', 'User')">{{ currentSubscription }}</v-list-item-subtitle>
          <v-list-item-subtitle v-else-if="currentProfile.isCustom">{{ $vuetify.lang.t('$vuetify.account.custom_type') }}</v-list-item-subtitle>
          <v-list-item-subtitle v-else>{{ $vuetify.lang.t('$vuetify.account.logged_in') }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <!-- TODO: implement metrics selector here -->

    <v-divider></v-divider>

    <v-list nav dense v-if="!justLogout">
      <v-list-item
        nav dense
        v-for="({ to, label, disabled }, i) in links"
        :key="`drawer-link-${i}`"
      >
        <v-list-item :to="to" exact :disabled="loading || (isFunction(disabled) ? disabled() : false)">
          <v-list-item-title>{{ label }}</v-list-item-title>
        </v-list-item>
      </v-list-item>
    </v-list>

    <v-divider></v-divider>

    <v-select
      :disabled="loading"
      :value="selectedUnitsSystem"
      :items="unitSystems"
      :menu-props="menuProps"
      item-text="label"
      item-value="abbr"
      color="white"
      class="mx-4 mt-3"
      return-object
      dense
      hide-details
      outlined
      @input="updateMetrics"
    >
    </v-select>
  </v-navigation-drawer>
</template>

<script>
import Menuable from '../mixins/Menuable';

export default {
  name: 'CoreDrawer',
  mixins: [Menuable],
  computed: {
    showDrawer() {
      const satisfyBreakpoint = this.$vuetify.breakpoint.smAndDown;

      if (!satisfyBreakpoint) {
        this.drawer = false;
      }

      return this.drawer && satisfyBreakpoint;
    }
  },
  methods: {
    onDrawerUpdate(drawerState) {
      this.drawer = drawerState;
    }
  }
};
</script>
