import vuetify from '@/plugins/vuetify';
import { SessionType } from '../store/actions/api';
import BaseRouteProvider from './BaseRouteProvider';
import { modulesMethods } from '../store/helpers';
import { ability, session } from '@/services/ability';

const filterBySessionType = (routeName, params, singleLink) => {
  if (typeof params === 'undefined' || params.hasOwnProperty('type') === false) {
    return false;
  }

  switch (params.type) {
    case SessionType.GAMES:
    case SessionType.PRACTICE:
    case SessionType.LIVEBP:
      // for game we have all items available
      return true;

    case SessionType.PITCHING:
      // for pitching session we have no hitting data
      return singleLink.to.name !== 'session.report.hitting';

    case SessionType.HITTING:
      // for hitting session we have no pitching data
      return singleLink.to.name !== 'session.report.pitching';

    default:
      return false;
  }
};

const filterSideNavBySessionType = (routeName, params, singleLink) => {
  if (typeof params === 'undefined' || params.hasOwnProperty('type') === false) {
    return false;
  }

  switch (params.type) {
    case SessionType.HITTING:
      return singleLink.to.name !== 'session.report.hitting.strike-zone';
    default:
      return true;
  }
};

const linksPerRoutes = [
  {
    routeNames: ['players', 'games', 'gamePractices', 'pitchingPractices', 'hittingPractices', 'LiveBP'],
    links: [
      {
        label: 'Players',
        to: { name: 'players' },
        visible: () => (modulesMethods.ormHas('players') && ability.can('read', 'Player')),
      },

      {
        label: vuetify.framework.lang.t('$vuetify.sessionTypes.Game'),
        to: { name: 'games' },
        visible: () => ability.can('read', session({})),
      },
      {
        label: vuetify.framework.lang.t('$vuetify.sessionTypes.GamePractice'),
        to: { name: 'gamePractices' },
        visible: () => ability.can('read', session({})),
      },
      {
        label: vuetify.framework.lang.t('$vuetify.sessionTypes.PitchingSession'),
        to: { name: 'pitchingPractices' },
        visible: () => ability.can('read', session({})),
      },
      {
        label: vuetify.framework.lang.t('$vuetify.sessionTypes.BattingSession'),
        to: { name: 'hittingPractices' },
        visible: () => ability.can('read', session({})),
      },
      {
        label: vuetify.framework.lang.t('$vuetify.sessionTypes.LiveBP'),
        to: { name: 'LiveBP' },
        visible: () => ability.can('read', session({})),
      },
    ],
  },
  {
    routeNames: [
      'session.summary',
      'session.report.pitching',
      'session.report.hitting',
      'session.table',
      'session.report.pitching.summary',
      'session.report.pitching.release-point',
      'session.report.pitching.pitch-location',
      'session.report.pitching.release-extension',
      'session.report.pitching.pitcher-performance',
      'session.report.pitching.pitch-movement',
      'session.report.hitting.summary',
      'session.report.hitting.strike-zone',
      'session.report.hitting.spray-chart',
      'session.report.hitting.batter-performance',
      'session.report.pitching.post-game',
      'session.report.hitting.post-game',
    ],
    links: [
      {
        label: 'Lineup & summary',
        to: { name: 'session.summary' },
      },
      {
        label: 'Pitching details',
        to: { name: 'session.report.pitching' },
      },
      {
        label: 'Hitting details',
        to: { name: 'session.report.hitting' },
      },
      {
        label: 'Data table',
        to: { name: 'session.table' },
      },
    ],
    filter: filterBySessionType,
  },
];

const linksPerRouteProvider = new BaseRouteProvider(linksPerRoutes);

export default linksPerRouteProvider;
