import { captureException } from '@sentry/vue';

class BaseRouteProvider {
  constructor(linksPerRoutes) {
    this.linksPerRoutes = (linksPerRoutes instanceof Array) ? linksPerRoutes: [];
  }

  getLinksForRoute(routeName, params) {
    const routeLinks = [];

    for (let i = 0; i < this.linksPerRoutes.length; i++) {
      const linksPerRoutes = this.linksPerRoutes[i];
      if (!linksPerRoutes.routeNames.includes(routeName)) {
        continue;
      }

      for (let j = 0; j < linksPerRoutes.links.length; j++) {
        const singleLink = Object.assign({}, linksPerRoutes.links[j]);
        // try to filter it..
        if (linksPerRoutes.hasOwnProperty('filter') && linksPerRoutes.filter instanceof Function) {
          try {
            if (linksPerRoutes.filter(routeName, params, singleLink) === false) {
              continue;
            }
          } catch (e) {
            captureException(e);
            continue;
          }
        }

        singleLink.nr = j;

        if (typeof params !== 'undefined') {
          singleLink.params = params;
        }

        routeLinks.push(singleLink);
      }
    }

    return routeLinks;
  }
}

export default BaseRouteProvider;
