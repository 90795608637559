import isFunction from 'lodash/isFunction';
import { mapState, mapGetters, mapActions } from 'vuex';
import { roleToLevel, servicePlans } from '@/modules/service-plan/tools/enums';
import LinksPerRouteProvider from '../../providers/LinksPerRouteProvider';
import { UNIT_SYSTEMS, defaultSystem } from '../../store/modules/user';
import { sync } from 'vuex-pathify';

export default {
  props: {
    justLogout: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      unitSystems: UNIT_SYSTEMS,
      menuProps: {
        offsetY: true,
      },
      loading: false,
    }
  },
  computed: {
    drawer: sync('app/layout.drawer'),
    hasSubscribeModule() {
      return this.$store.hasModule('servicePlan')
    },
    hasUserProfileModule() {
      return this.$store.hasModule('userProfile');
    },
    ...mapGetters(['currentProfile', 'hasSubscribed']),
    ...mapState({
      selectedUnitsSystem: state => state.user.selectedUnitsSystem || UNIT_SYSTEMS[defaultSystem],
      profileLink() {
        return (
          (
            this.hasUserProfileModule
            && this.$can('edit', 'User')
          )
            ? '/profile'
            : ''
        );
      },

      links() {
        return this.$store.state.system.initialized
          ? LinksPerRouteProvider
            .getLinksForRoute('games', this.$route.params)
            .filter(({ visible }) => {
              return isFunction(visible) ? visible() : visible;
            })
          : [];
      },
    }),

    currentSubscription() {
      if (this.hasSubscribeModule && this.hasSubscribed) {
        const roles = Object.keys(roleToLevel).reverse();
        const owned = roles.filter(code => this.currentProfile.roles.find(role => role.Code === code));
        if (owned.length) {
          return servicePlans[roleToLevel[owned[0]]].title;
        }
      }
      return '';
    },
  },
  methods: {
    isFunction,
    ...mapActions({
      updateMetrics: 'USER_METRICS',
    }),
  }
}
